 // Track.js

 import React, { useEffect, useState, Fragment } from "react";
 import axios from "axios";
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
 import logo from "./CainiaoLogo.svg.png";
 import "./trask.css";
 
 const Track = ({ trackingNumber }) => { // Accept trackingNumber as a prop
   const apiUrl = "https://api.ship24.com/public/v1/tracking/search";
   const apiKey = "apik_mKNmH335Spq3KVwgxoqREHe0G68ktH";
 
   const [trackingInfo, setTrackingInfo] = useState([]);
 
   useEffect(() => {
     const config = {
       headers: {
         Authorization: `Bearer ${apiKey}`,
       },
     };
     const postData = {
       trackingNumber, // Use the trackingNumber from the prop
     };
 
     axios
       .post(apiUrl, postData, config)
       .then((response) => {
         setTrackingInfo(response.data);
       })
       .catch((error) => {
         console.error("Error:", error);
       });
   }, [trackingNumber]); // Make the effect depend on trackingNumber prop
 
   // Function to format date and time
   const formatDateTime = (datetime) => {
     const options = {
       year: "numeric",
       month: "short",
       day: "numeric",
       hour: "2-digit",
       minute: "2-digit",
       second: "2-digit",
     };
     return new Date(datetime).toLocaleString(undefined, options);
   };
 
   console.log(trackingInfo);
 
   return (
     <Fragment>
       <div className="container">
         <div className='wallpaper'>
           <div className="arrow-api mt-5" >
             <div className="myProgress col-12">
               <div className="myBar" style={{ backgroundColor: "#4775ed", borderRadius: "16px" }}>
                 <span className="label"><FontAwesomeIcon className='i' icon={faChevronRight} /></span>
               </div>
             </div>
           </div>
           <div className="content-api mt-5">
             <section className="time-line-area">
               {trackingInfo.data &&
                 trackingInfo.data.trackings[0].events.map((event) => (
                   <div className="message" key={event.eventId}>
                     <p className="date">{formatDateTime(event.occurrenceDatetime)}</p>
                     <div className="flexy com" >
                       <div className="img-mark" ><img src={logo} width={"30px"} alt="" /></div>
                       <div><p style={{paddingLeft:"10px",textTransform:"uppercase"}}>{event.courierCode}</p></div>
                     </div>
                     <p className="pt-2">{event.status}</p>
                   </div>
                 ))}
             </section>
           </div>
         </div>
       </div>
     </Fragment>
   );
 };
 
 export default Track;