import './App.css';
import { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Track from './components/Track';
import anime from 'animejs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [showTrackInfo, setShowTrackInfo] = useState(false);

  const handleChange = (event) => {
    setTrackingNumber(event.target.value);
  };
  const [loading, setLoading] = useState(false);

  const handleTrackButtonClick = async () => {
    setLoading(true);
  
    // Perform tracking logic here (you can use a setTimeout as a placeholder)
    await new Promise((resolve) => setTimeout(resolve, 2000));
  
    setLoading(false);
    setShowTrackInfo(true); // Display track info after loading
  };
  

  useEffect(() => {
    // Content animation
    anime({
      targets: '.content',
      translateY: [-100, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: 'easeInOutQuad'
    });

    // Button animation
    anime({
      targets: '.track-button',
      scale: [1, 1.2, 1],
      duration: 1000,
      easing: 'easeInOutQuad',
      loop: true,
    });
  }, []);

  return (
    <Fragment>
      <div className='bg'>
        <div className='container'>
          <div className='content mt-5 text-center'>
            <p style={{ color: "#fff", fontFamily: "Arial, sans-serif" }}>
              Universal parcel tracking to track your package from UPS,
              FEDX, DHL, USPS, China Post and more!
            </p>
            <br />
            <form action="" className="track-form col-12">
              <input
                type="text"
                placeholder="Enter tracking number"
                value={trackingNumber}
                onChange={handleChange}
              />
 <button
  className={`track-button ${loading ? 'loading' : ''}`}
  style={{ borderRadius: '50%' }}
  type="button"
  onClick={handleTrackButtonClick}
  disabled={loading}
>
  {loading ? (
    <FontAwesomeIcon className="spinner" icon={faSpinner} spin />
  ) : (
    <FontAwesomeIcon className='i' icon={faArrowRight} />
  )}
</button>

            </form>
            {showTrackInfo && <Track trackingNumber={trackingNumber} />}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default App;


